<template>
  <div class="page">
    <esmp-table-wrapper
      class="services mb-20"
      title="Выбранные услуги"
      :is-column-settings-button-showed="false"
      :is-allow-full-page="false"
    >
      <template #header-actions>
        <esmp-select
          placeholder="Поиск услуг для праздников"
          :remote-method="debouncedSearchMethod"
          :loading="searchLoading"
          @on-select="setSelectedVal"
          ref="select"
          filterable
          class="services__search"
        >
          <esmp-select-option
            v-for="r in searchResults"
            :key="r.id"
            :value="r.id"
          >
            {{ r.name }}
          </esmp-select-option>
        </esmp-select>
      </template>
      <esmp-table
        :columns="servicesHeaders"
        :rows="services"
        :loading="loading1"
        stripe
      >
        <template #cell-actions="{ tr }">
          <div class="feasts__actions">
            <span class="feasts__actions-item" @click="deletePostcardsService(tr)">
              <esmp-icon name="trash" />
            </span>
          </div>
        </template>
      </esmp-table>
    </esmp-table-wrapper>
    <esmp-table-wrapper
      class="feasts"
      title="Праздники"
      :is-column-settings-button-showed="false"
      :is-allow-full-page="false"
    >
      <template #header-actions>
        <esmp-button size="small" @click="editFeastsItem()">
          Добавить
        </esmp-button>
      </template>
      <esmp-table
        :columns="columns"
        :rows="rows"
        :loading="loading2"
        stripe
      >
        <template #cell-isArchive="{ td, tr }">
          <esmp-switch
            :value="!td"
            shape="circular"
            @input="(val) => isPublicChange(tr, val)"
          />
        </template>
        <template #cell-defaultCongratulationMessage="{ td }">
          {{ td | truncate(55) }}
        </template>
        <template #cell-actions="{ tr }">
          <div class="feasts__actions">
            <span class="feasts__actions-item" @click="editFeastsItem(tr)">
              <esmp-icon name="doc-edit" />
            </span>
            <!--
            <span class="feasts__actions-item" @click="showConfirmModal(tr)">
              <esmp-icon name="trash" />
            </span>
            -->
          </div>
        </template>
      </esmp-table>
    </esmp-table-wrapper>
    <feasts-form
      v-model="editModalShowed"
      :id="itemToEdit.id"
      :title="itemToEdit.title"
      :is-custom-congratulation-message-need="itemToEdit.isCustomCongratulationMessageNeed"
      :default-congratulation-message="itemToEdit.defaultCongratulationMessage"
      :postcards="itemToEdit.postcards"
      @on-save="save"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import FeastsForm from '@/pages/admin/feasts/FeastsForm.vue';

const itemDefault = {
  title: '',
  isCustomCongratulationMessageNeed: false,
  defaultCongratulationMessage: '',
  postcards: [],
};

export default {
  name: 'Feasts',
  components: { FeastsForm },
  data() {
    return {
      loading1: false,
      loading2: false,
      searchLoading: false,
      searchResults: [],
      itemDefault,
      itemToEdit: itemDefault,
      editModalShowed: false,
      editModalShowedLoading: true,
      columns: [
        {
          title: 'ID',
          key: 'id',
        },
        {
          title: 'Заголовок',
          key: 'title',
        },
        {
          title: 'Специальное поздравление',
          key: 'defaultCongratulationMessage',
        },
        {
          title: 'Активный',
          key: 'isArchive',
        },
        {
          title: '',
          key: 'actions',
        },
      ],
      rows: [],
      servicesHeaders: [
        {
          title: 'Название услуги',
          key: 'name',
        },
        {
          title: '',
          key: 'actions',
        },
      ],
      services: [],
    };
  },
  methods: {
    searchMethod(query) {
      if (!query) {
        this.searchResults = [];
      } else {
        this.searchLoading = true;
        this.$API.services.simpleSearch(query)
          .then(({ data }) => {
            this.searchResults = data;
          })
          .finally(() => {
            this.searchLoading = false;
          });
      }
    },
    setSelectedVal(obj) {
      this.loading1 = true;
      const isExist = this.services.find((el) => el.id === obj.value);
      if (!isExist) {
        const selectedService = this.searchResults.find((el) => el.id === obj.value);
        this.$API.feasts.addPostcardsService(selectedService).then(() => {
          this.services.push(selectedService);
        }).finally(() => {
          this.loading1 = false;
        });
      }
      this.searchResults = [];
      this.$refs.select.setQuery(null);
    },
    deletePostcardsService(selectedService) {
      this.loading1 = true;
      this.$API.feasts.deletePostcardsService({
        serviceId: selectedService.id,
        sourceSystem: selectedService.sourceSystem,
      }).then(() => {
        this.services = this.services.filter((el) => el.id !== selectedService.id);
      }).finally(() => {
        this.loading1 = false;
      });
    },
    isPublicChange(item, val) {
      this.loading2 = true;
      this.$API.feasts.updateFeastState(item.id, { isArchive: !val }).then(() => {
        this.$EsmpNotify.$show('Статус успешно обновлен', 'success');
      }).finally(() => {
        this.loading2 = false;
      });
    },
    async editFeastsItem(item) {
      if (item) {
        this.itemToEdit = await this.$API.feasts.getFeast(item.id).then(({ data }) => data);
      } else {
        this.itemToEdit = this.itemDefault;
      }
      this.editModalShowed = true;
    },
    getFeasts() {
      this.loading2 = true;
      this.$API.feasts.getFeasts().then(({ data }) => {
        this.rows = data.content;
      }).finally(() => {
        this.loading2 = false;
      });
    },
    getPostcardsServices() {
      this.loading1 = true;
      this.$API.feasts.getPostcardsServices().then(({ data }) => {
        this.services = data;
      }).finally(() => {
        this.loading1 = false;
      });
    },
    save(dto) {
      this.loading2 = true;
      if (dto.id) {
        this.$API.feasts.updateFeast(dto.id, dto)
          .then(() => {
            this.rows = this.rows.map((el) => {
              if (el.id === dto.id) return dto;
              return el;
            });
          })
          .finally(() => {
            this.loading2 = false;
          });
      } else {
        this.$API.feasts.createFeast(dto)
          .then(({ data }) => this.rows.push(data))
          .finally(() => {
            this.loading2 = false;
          });
      }
    },
  },
  created() {
    this.getFeasts();
    this.getPostcardsServices();
    this.debouncedSearchMethod = debounce(this.searchMethod, 500);
  },
};
</script>

<style lang="scss">
.feasts {
  &__actions {
    display: flex;
    flex-direction: row;
    &-item {
      cursor: pointer;
      margin: 0 0 0 10px;
      &:first-child {
        margin: 0;
      }
    }
  }
}

.form-item {
  margin: 20px 0 0 0;
  &:first-child {
    margin: 0;
  }
}

.services {
  &__search {
    max-width: 400px;
  }
}

.services, .feasts {
  .esmp-table__thead-tr {
    .esmp-table__thead-th:last-child {
      width: 48px;
    }
  }
}

.feasts {
  .esmp-table__thead-tr {
    .esmp-table__thead-th:first-child {
      width: 42px;
    }
    .esmp-table__thead-th:nth-child(4) {
      width: 48px;
    }
  }
}
</style>
